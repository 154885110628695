export const validatorPositive = value => {
    if (value >= 0) {
        return true
    }
    return false
}

export const validatorPassword = password => {
    /* eslint-disable no-useless-escape */
    const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
        /* eslint-enable no-useless-escape */
    const validPassword = regExp.test(password)
    return validPassword
}

export const validatorEgyptID = egyptID => {
    /* eslint-disable no-useless-escape */
    const regExp = /^(2|3)[0-9][1-9][0-1][1-9][0-3][1-9](01|02|03|04|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d\d\d\d\d$/
        /* eslint-enable no-useless-escape */
    const validEgyptID = regExp.test(egyptID)
    return validEgyptID
}
export const validatorEgyptPhone = egyptPhone => {
    /* eslint-disable no-useless-escape */
    const regExp = /^0[2]\d{8}$/
        /* eslint-enable no-useless-escape */
    const validEgyptPhone = regExp.test(egyptPhone)
    return validEgyptPhone
}
export const validatorEgyptMobile = egyptMobile => {
    /* eslint-disable no-useless-escape */
    const regExp = /^01[0125][0-9]{8}$/
        /* eslint-enable no-useless-escape */
    const validEgyptMobile = regExp.test(egyptMobile)
    return validEgyptMobile
}

export const validatorCreditCard = creditnum => {
    /* eslint-disable no-useless-escape */
    const cRegExp = /^(?:3[47][0-9]{13})$/
        /* eslint-enable no-useless-escape */
    const validCreditCard = cRegExp.test(creditnum)
    return validCreditCard
}

export const validatorUrlValidator = val => {
    if (val === undefined || val === null || val.length === 0) {
        return true
    }
    /* eslint-disable no-useless-escape */
    const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
        /* eslint-enable no-useless-escape */
    return re.test(val)
}